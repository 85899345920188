<template>
  <div class="card setting_card">
    <div class="title">Coupon Settings</div>

    <div class="setting_body">

      <div class="form-group d-flex align-items-center ml-10">

        <div>
          <label for="free_limit" class="name">Free Limit</label>
        </div>

        <div class="form-view ">
          <input ref="free_limit" id="free_limit" tabindex="1" class="form-control form-control-lg form-control-solid"
            type="text" height="50" v-bind:value="currentUser.userData.free_limit" @input="filterNonNumeric" />
          <!-- <i class='far fa-edit'></i> -->
          <!-- v-model="free_limit" -->

        </div>
        <div>
          <button type="button" class="btn btn-primary  h6" @click="updateLimit()">Save</button>
        </div>

      </div>
      <div class="border border-secondary mb-5" style="border: 3px solid black;">
        <form action="" method="" @submit="addcoupon()" class="">


          <h2 class="mt-5 mb-3 text-dark ml-6">Discount Coupon </h2>
          <div class="p-5">
            <div class="d-flex align-items-center">
              <div>
                <div class="form-group d-flex align-items-center ml-4">
                  <div class="col-xl-4 mr-6"> <label for="coupon" class="coupon ml-8">Coupon</label></div>
                  <div class="col-xl-8 ">
                    <input ref="coupon" id="coupon" tabindex="1" class="form-control form-control-lg form-control-solid"
                      type="text" v-model="coupon_details.couponcode" height="60" required />
                    <!-- <i class='far fa-edit'></i> -->
                  </div>


                </div>
              </div>
              <div class="d-flex">
                <div class="form-group d-flex align-items-center ml-4">
                  <div class="col-xl-6 mr-6"> <label for="expiry_date" class="name ml-8">Expiry date</label></div>

                  <div class="col-xl-6">
                    <input ref="expiry_date" id="expiry_date" tabindex="1"
                      class="form-control form-control-lg form-control-solid" type="date" height="60"
                      v-model="coupon_details.expiry_date" required />
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div class="col-sm-6">
                  <div class="form-group  d-flex align-items-center">
                    <div class=" d-flex">
                      <div class="align-items-center ml-2">
                        <input class="form-check-input" type="radio" name="" id="discount_type1"
                          v-model="coupon_details.discount_type" value="R">
                      </div>

                      <div class="">
                        <label class="form-check-label ml-3" for="discount_type">
                          Record Discount
                        </label>
                      </div>
                    </div>
                    <div class="">
                      <input ref="record_discount" id="record_discount" tabindex="1"
                        class="form-control form-control-lg form-control-solid" type="text"
                        v-model="coupon_details.record_discount" height="60"
                        v-bind:disabled="coupon_details.discount_type == 'A'" required @input="filterNonNumeric"
                        autocomplete="off" />
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group  d-flex align-items-center">

                    <div class="col-xl-6 d-flex align-center">
                      <div class=" align-items-center"><input class="form-check-input" type="radio" name=""
                          id="discount_type2" checked v-model="coupon_details.discount_type" value="A"></div>
                      <div>
                        <label class="form-check-label ml-4" for="flexRadioDefault2">
                          Percentage Discount
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <input ref="amount_discount" id="amount_discount" tabindex="1"
                        class="form-control form-control-lg form-control-solid"
                        v-bind:disabled="coupon_details.discount_type == 'R'" type="text"
                        v-model="coupon_details.amount_discount" height="60" required v-on:keypress="NumbersOnly"
                        autocomplete="off" />
                    </div>

                  </div>
                </div>
              </div>
              <!-- <i class='far fa-edit'></i> -->



            </div>
            <button type="submit" class="btn btn-primary mt-10 h6">Add Coupon</button>
          </div>


        </form>
      </div>
      <div>

        <b-table no-border-collapse hover :items="Allcoupons" :fields="fields" head-row-class="table-header" sort-icon
          class="task-list custom-b-table-class">
          <template #cell(coupon)="data">

            <div class="text-left">{{ data.item.couponcode }}</div>
          </template>

          <template #cell(expiry_date)="data">

            <div class="text-left">
              {{ formatDate(data.item.expiry_date) }}</div>
          </template>

          <template #cell(record_discount)="data">

            <div class="text-left">
              <!-- {{ data.item.record_discount }} -->
              {{ data.item.record_discount ? data.item.record_discount : "----" }}
            </div>
          </template>

          <template #cell(amount_discount)="data">

            <div class="text-left">
              {{ data.item.amount_discount ? data.item.amount_discount : "----" }}
            </div>
          </template>

          <template #cell(action)="data">
            <div class="text-center">
              <button @click="deleteCoupon(data.item.id)">
                <inline-svg src="media/svg/icons/General/trash_icon.svg" />
              </button>
            </div>

          </template>
        </b-table>
      </div>
    </div>

  </div>
</template>
<script>

import {
  ADD_COUPON,
  GET_COUPON,
  DELETE_COUPON,
} from "@/core/services/store/auth.module";
import { UPDATE_LIMIT } from "@/core/services/store/auth.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {

      fields: [
        {
          key: 'coupon',
          Label: 'Coupon',
          sortable: true
        },

        {
          key: 'expiry_date',
          Label: 'Expiry Date',
          sortable: true,
        },

        {
          key: 'record_discount',
          Label: 'Record Discount',
          sortable: true,
        },
        {
          key: 'amount_discount',
          Label: 'Amount Discount',
          sortable: true,
        },
        {
          key: 'action',
          Label: 'Action',
          thClass: "text-center",
          // Variant applies to the whole column, including the header and footer

        }
      ],
      // record_dicount_check:false,
      // amount_dicount_check:true,
      free_limit: "",
      Allcoupons: {},
      coupon_details: {
        couponcode: "",
        expiry_date: "",
        discount_type: "A",
        record_discount: "",
        amount_discount: "",
        currentUser: this.currentUser,
      }


    }

  },
  mounted() {
    this.getcoupon();
    console.log(this.currentUser, "currentUser")
  },
  methods: {
    addcoupon() {
      
      this.$store.dispatch(ADD_COUPON, this.coupon_details)
        .then(() => {
          this.getcoupon();
          this.coupon_details.couponcode = "";
          this.coupon_details.expiry_date = "";
          this.coupon_details.discount_type = "A";
          this.coupon_details.record_discount = "";
          this.coupon_details.amount_discount = "";
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title:
              "Invalid Data issue",
            confirmButtonClass: "btn btn-secondary"
          });
        });
    },
    filterNonNumeric() {
      // Replace non-numeric characters with an empty string
      this.coupon_details.record_discount = this.coupon_details.record_discount.replace(/[^0-9]/g, "");
      this.free_limit = this.free_limit.replace(/[^0-9]/g, "")
    },

    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(date);
      // {dateStyle: 'short'}
    },
    getcoupon() {
      this.$store.dispatch(GET_COUPON)
        .then(response => {
          this.Allcoupons = response;
          // this.Allcoupons.push( response );
          console.log(this.Allcoupons, "getcoupons")
        });
    },
    updateLimit() {
      this.$store.dispatch(UPDATE_LIMIT, { free_limit: this.$refs.free_limit.value })
        .then(response => {
          console.log(response, "free_limit");
        });
    },
    deleteCoupon(coupon_id) {
      console.log(coupon_id, "coupon_id");

      Swal.fire({
        title: "Are you sure you want to delete your coupon?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(DELETE_COUPON, {
              coupon_id: coupon_id,

            })
            .then(() => {
              setTimeout(() => {
                this.getcoupon();
              }, 1000);
            });
        }
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  }
}
</script>
<style scoped>
.title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height */
  color: #3D415B;
  padding: 20px;
}

.setting_card {

  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
}

.form-group label {

  /* height: px; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  text-align: right;
  margin-right: 10px;
  letter-spacing: -0.408px;

  color: #0E0E0F;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.form-group .form-check-input {
  height: 20px;
  width: 20px;
}

/* .form-check label{
    height: 22px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 22px;

text-align: right;
margin-right: 10px;
letter-spacing: -0.408px;

color: #0E0E0F;
  } */
.form-control.form-control-solid {
  font-size: 15px;
  font-weight: 400;
  width: 210px;
  height: 50px;
  letter-spacing: -0.408px;
  font-feature-settings: 'case' on;
  background: #F4F5F9;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border-radius: 8px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  border: 0;
  color: #6F7392;

}

.setting_body {
  padding: 15px;
}

.task-list {
  border-collapse: separate;
  border-spacing: 0px 10px;
}

.task-list thead th {
  height: 13px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  /* box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04); */
  /* border: solid 1px rgba(171, 164, 164, 0.8); */
  border-bottom: 0;
  border-left: 0;
  font-family: 'Poppins';

  position: absolute;
  background-color: #EBEBEB;
  /* border-right: 0; */
}

.tableheads::after {
  content: '';
  height: 22px;
  width: 22px;
  border-right: solid 1px rgba(184, 179, 179, 0.8);
  position: absolute !important;
  top: 11px;
  left: -17px;
  border-left: 0;

}

.task-list tbody tr {
  padding: 23px 25px 23px 28px;
  border-radius: 8px;
  /* box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04); */
  border: solid 1px rgba(96, 94, 94, 0.8);
  /* background-color: white; */
}

.task-list tbody tr td {
  width: 97px;
  height: 23px;
  left: 68px;
  top: 19px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  /* identical to box height */

  /* color: #3D415B; */
  color: #3D415B;
  cursor: pointer;
}
</style>